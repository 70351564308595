.p-sidebar {
  width: 440px !important;
}

.p-sidebar .p-sidebar-header {
  position: relative;
  z-index: 10;
}

.p-sidebar-content {
  padding: 0 !important;
  margin-top: -50px;

  h2 {
    font-size: 15px;
    color: $gray;
    margin: 5px 20px;
  }
}

.sidebar-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  border-bottom: 1px solid $lightGray;
  //transition: background 0s linear 0.1s;

  &.without-padding {
    padding: 10px 0;
  }

  &.clickable {
    cursor: pointer;

    &:hover {
      background: $hoverLight;
    }

    &:active {
      background: darken($hoverLight, 5%) !important;
    }
  }

  &:last-child {
    border: none;
  }
}

.sidebar-content {
  display: flex;
  flex-direction: column;
}

.sidebar-title {
  font-size: 15px;
  color: $dark;
}

.sidebar-info {
  font-size: 14px;
  color: $gray;
}

.sidebar-text {
  color: $dark;
  font-size: 15px;
  margin: 10px 0 0 0;
}
